:root {
    --loader-size: 50px;
    --dot-size: 6px;
    --loader-bg: #71296f;
    --dot-color: white;
}

.Loader {
    z-index: 9999999999999;
    position: fixed;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    background-color: var(--loader-bg);
}

.Loader h1 {
    /* color: var(--dot-color); */
    font-size: 1.5em;
    /* animation: fading 2.5s ease-in-out infinite; */
}

.Loader p {
    /* color: var(--dot-color);
    opacity: 80%; */
    font-size: 0.8em;
}

.Loader-box {
    margin: 50px;
    flex: 0 0 auto;
    height: var(--loader-size);
    width: var(--loader-size);
}

.Loader .box {
    position: absolute;
    height: var(--loader-size);
    width: var(--loader-size);
    animation: rotating 4s ease-in infinite;
    animation-delay: calc(var(--id) * 0.5s);
}

.Loader .dot {
    background-color: var(--dot-color);
    height: var(--dot-size);
    width: var(--dot-size);
    border-radius: 100%;
}

.container-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 30vw;
    background-color: #DB4437;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#preloader {
    width: 60%;
}

@keyframes rotating {
    0% {
        opacity: 0;
        transform: rotateZ(0);
    }

    25% {
        opacity: 100%;
        transform: rotateZ(160deg);
    }

    75% {
        opacity: 200%;
        opacity: 100;
    }

    80% {
        transform: rotateZ(300deg);
        opacity: 100;
    }

    100% {
        transform: rotateZ(350deg);
        opacity: 0;
    }
}

@keyframes fading {
    0% {
        opacity: 40%;
    }

    50% {
        opacity: 90%;
    }

    100% {
        opacity: 40%;
    }
}