.footer {
  background-image: linear-gradient(180deg, #71296f 0%, #391538 100%);
}

.footer-inner {
  padding: 35px 0;
  background-size: 50%;
  background-position-x: right;
  background-repeat: no-repeat;
  background-image: url("../../../assets/footer-bg.svg");
}

.footer-logo img {
  max-width: 250px;
}

.footer-menu h4 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}

.footer-menu ul {
  padding: 0;
}

.footer-menu ul li {
  list-style-type: none;
}

.footer-menu ul li a {
  color: #ffffff;
  padding: 6px 0;
  font-weight: 300;
  display: block;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-menu ul li a:hover {
  color: #debd5f;
}

.footer-bottom {
  margin-top: 10px;
  border-top: 2px solid #debd5f;
}

.footer-bottom p {
  padding-top: 20px;
  margin: 0;
  color: #ffffff;
  font-weight: 300;
  text-align: center;
}

.footer-social {
  color: #ffffff;
  font-weight: 300;
  letter-spacing: 1px;
  margin-top: 40px;
}

.footer-social ul {
  padding: 0;
}

.footer-social ul li {
  display: inline;
  list-style-type: none;
}

.footer-social ul li a {
  width: 36px;
  height: 36px;
  line-height: 49px;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #ffffff;
  display: inline-block;
}

.footer-social ul li a svg {
  fill: #4e1c4d !important;
}

.footer-social ul li a:hover svg {
  fill: #debd5f !important;
}


@media only screen and (max-width: 900px) {
  .footer-inner {
    background-size: cover;
  }

  .footer-logo img {
    max-width: 185px;
  }
}