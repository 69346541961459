.modal-form {
    padding: 30px 40px;
    background-image: url(../../../assets/logo-bg.svg);
    background-color: #71296F;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
}

.modal-form .modal-header {
    color: #ffffff;
    margin-bottom: 20px;
}

.modal-form .modal-header h3 {
    margin: 0;
    font-size: 24px;
}

.modal-form .modal-header p {
    margin: 0;
}