.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.header header {
  padding-left: 10%;
  padding-right: 10%;
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(16px);
  background: #71296fad;
  text-transform: uppercase;
  font-weight: 600;
}

.top-bar .header-images {
  display: flex;
  padding: 0 20px;
  align-items: center;
  color: white;
}

.top-bar .header-images img {
   margin-right: 20px;
}

.top-bar .header-numbers {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-bar .header-numbers li {
  color: #ffffff;
  list-style-type: none;
}

.top-bar .header-numbers li a {
  text-decoration: none;
  padding: 10px 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-bar .header-numbers li a img {
  max-width: 24px;
  margin-right: 10px;
}

.top-bar .header-numbers li a p {
  margin: 0;
}

.navbar-inner {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
  opacity: 1;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  background: linear-gradient(95deg, #ffffff66 0%, #ffffff00 100%);
}

.navbar-dark {
  background: #71296f;
}

.navbar-inner .nav-list {
  list-style-type: none;
}

.navbar-inner .nav-list a {
  font-size: 22px/30px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
}

.navbar-inner .nav-list:nth-child(4) a {
  padding-right: 110px;
  padding-left: 110px;
}

.navbar-inner .nav-list img {
  width: 175px;
  top: -51px;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

@media only screen and (max-width: 1200px) {
  .header header {
    left: 5%;
    right: 5%;
  }
}

@media only screen and (max-width: 900px) {
  .header {
    display: none;
  }

  .top-bar {
    display: none;
  }
}