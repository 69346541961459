@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* @import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,700|Roboto:400"); */
body {
  font-family: "Montserrat", sans-serif !important;
  color: #2e3034;
}

img {
  max-width: 100%;
}

.MuiTabs-indicator {
  display: none;
}

.add-button {
  border: 0;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
}

.add-button:hover {
  color: #debd5f;
}

.search-btn {
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  background: #ffffff;
  border: 3px solid #e8e8e8;
}

.sm-button {
  display: none;
}

.search-btn-cross {
  border: none;
  padding: 8px;
  cursor: pointer;
  background: transparent;
}

/* DatePicker Styling */

.MuiPickersCalendarHeader .switchHeader {
  color: #71296f;
  text-transform: uppercase;
}

.MuiPickersCalendarHeader .dayLabel {
  text-transform: uppercase;
}

.MuiPickersDay .day {
  color: #707070;
}

.MuiPickersDay daySelected {
  background-color: #71296f;
}

.MuiPickersDay .daySelected:hover {
  background-color: #71296f;
}

.MuiPickersDay .current {
  color: #71296f;
}

.MuiSvgIcon-root {
  fill: #debd5f;
}

.MuiFilledInput-root:before,
.MuiFilledInput-root:after {
  display: none;
}

.cities-gallery {
  background: #ffffff;
  padding: 100px 0;
}

.cities-main-heading {
  margin: 20px auto 100px auto;
  font-size: 40px;
  color: #71296f;
  font-weight: 700;
  line-height: 55px;
  width: fit-content;
  text-align: center;
  position: relative;
}

.cities-main-heading::before {
  top: 47%;
  left: -60px;
  content: "";
  width: 50px;
  height: 3px;
  position: absolute;
  background: #debd5f;
}

.cities-main-heading::after {
  top: 47%;
  right: -60px;
  content: "";
  width: 50px;
  height: 3px;
  position: absolute;
  background: #debd5f;
}

.mosaic-image {
  position: relative;
}

.mosaic-image:after {
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: rgb(0 0 0 / 35%);
}

.image-title {
  margin: 0;
  z-index: 1;
  position: absolute;
  color: #ffffff;
  line-height: 67px;
  letter-spacing: 20px;
  font-size: 50px;
  text-transform: uppercase;
}

.image-title.bottom {
  bottom: 5%;
  left: 0;
  right: 0;
  text-align: center;
}

.image-title.top {
  top: 5%;
  left: 0;
  right: 0;
  text-align: center;
}

.image-title.left {
  left: 5%;
  top: 0;
  bottom: 0;
  text-align: center;
}

.image-title.right {
  right: 5%;
  top: 0;
  bottom: 0;
  text-align: center;
}

.image-title.small {
  font-size: 24px;
}

.image-title.vertical {
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.main-banner-home {
  position: relative;
  min-height: 100vh;
}

.banner {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding-top: 150px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* .banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: #00000026;
} */

.banner-inner {
  width: 100%;
}

.banner-heading {
  color: #ffffff;
  font-size: 50px;
  font-family: "Cinzel", sans-serif;
  text-align: center;
}

.banner-form {
  margin: 0;
}

.banner-bottom {
  padding: 20px 0;
}

.banner-right-heading {
  position: absolute;
  bottom: 46px;
  left: 20px;
  color: #debd5f;
}

.brand-logo {
  padding: 15px;
  text-align: center;
}

.brand-logo img {
  width: 100%;
  max-width: 160px;
}

.arrow-down {
  padding: 20px;
  text-align: center;
}

.home-section {
  padding: 80px 0;
}

.why-us img {
  width: 100%;
}

.why-us p {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 25px;
  font-weight: 300;
}

.why-us-heading {
  margin: 0;
  font-weight: bold;
  font-size: 26px;
  color: #71296f;
  text-transform: uppercase;
}

.why-us-right .right-heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.why-us-right .right-heading h2 {
  margin: 0;
  line-height: 150px;
  font-size: 100px;
  font-weight: 400;
  color: #debd5f;
}

.why-us-right .right-heading p {
  font-size: 26px;
  line-height: 34px;
  position: relative;
}

.why-us-right .right-heading p::before {
  position: absolute;
  content: "";
  bottom: -10px;
  left: 0;
  width: 30%;
  height: 3px;
  border-radius: 25px;
  background: #debd5f;
}

.why-us-right .right-heading p::after {
  position: absolute;
  content: "";
  bottom: -10px;
  right: 0;
  width: 70%;
  height: 3px;
  border-radius: 25px;
  background-color: #71296f;
}

.why-us-text-box {
  margin: 20px 0;
  padding: 20px;
  /* border-radius: 16px; */
  background-color: #71296f;
  /* background-image: linear-gradient(180deg, #71296f 0%, #391538 100%); */
}

.why-us-text-box-inner {
  padding: 20px;
  color: white;
  /* border-radius: 16px;
  background-color: #71296f;
  background-image: linear-gradient(-127deg,
      #a1055a 0%,
      #b63c7fc6 40%,
      #ffffff00 100%); */
}

.why-us-text-box-heading {
  margin: 0;
  font-size: 34px;
  font-weight: 400;
}

.why-us-text-box-inner .heading {
  padding-left: 15px;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  text-transform: uppercase;
  position: relative;
  margin: 10px 0;
}

.why-us-text-box-inner .heading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: #debd5f;
}

.arrow {
  cursor: pointer;
  /* padding: 50px; */
  margin-bottom: 20px;
  /* border-radius: 16px; */
  text-align: center;
  /* background-color: #71296f; */
}

.arrow img {
  width: fit-content;
}

.arrow .left {
  transform: rotate(180deg);
}

.home-common-heading {
  font-size: 40px;
  color: #debd5f;
  position: relative;
  padding-left: 25px;
  letter-spacing: 50;
  text-transform: uppercase;
}

.home-common-heading.invert {
  text-align: right;
  padding-right: 25px;
}

.home-common-heading span {
  color: #71296f;
}

.home-common-heading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background-image: linear-gradient(180deg, #71296f 0%, #391538 100%);
}

.home-common-heading p {
  font-size: 16px;
  font-weight: 400;
  color: #2e3034;
}

.home-common-heading.invert::before {
  right: 0;
  left: auto;
}

.certificate-box {
  width: 100%;
  padding: 75px 0;
  border-radius: 16px;
  background-color: #71296f;
}

.certificate-box .box-inner {
  margin: auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  background-color: white;
}

.certificate-box .box-inner img {
  max-width: 100%;
  filter: grayscale(100%);
  transition: all 0.3s ease;
}

.certificate-box:hover .box-inner img {
  max-width: 100%;
  filter: grayscale(0);
}

.deals-section {
  margin-top: 50px;
  background-image: linear-gradient(180deg, #71296f 0%, #391538 100%);
}

.deals-section-inner {
  background-image: url("./assets/logo-bg.svg");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

.deals-slider {
  position: relative;
  padding: 50px 0;
}

.deals-item {
  position: relative;
  color: #ffffff;
  text-align: center;
}

.deals-item .deals-text::after {
  content: "";
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #debd5f;
}

.slide-image-to-right {
  position: absolute;
  animation: infinite;
  animation-name: run;
  -webkit-animation: infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 20s;
}

/************** Slider **************/
.custom-slide {
  opacity: 0;
  transition-duration: all 1s ease;
}

.custom-slide.slide-active {
  opacity: 1;
  transition-duration: 1s;
}

@keyframes run {
  0% {
    left: 10%;
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    left: 85%;
    opacity: 0;
  }
}

@-webkit-keyframes run {
  0% {
    left: 0;
  }

  50% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

.deals-img {
  position: relative;
  padding-bottom: 100px;
}

.deals-item img {
  opacity: 0;
}

.MuiTimelineDot-root.MuiTimelineDot-filled.MuiTimelineDot-filledPrimary {
  border-radius: 0 !important;
}

.MuiTimelineItem-root:before {
  display: none;
}

.MuiTimeline-root {
  padding-left: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

/* .slide-image-to-right::after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 40px;
  background-color: #debd5f;
  width: 1px;
  margin: auto;
  left: 0;
  right: 0;
  opacity: 0;
} */

.deals-heading {
  position: absolute;
  top: -45px;
  z-index: 1;
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 50%;
  color: #71296f;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
}

/* .deals-item.active .deals-heading {
  background-color: #debd5f;
} */

.deals-text {
  position: relative;
  padding: 0 25px;
}

.deals-text p {
  font-size: 16px;
  font-weight: 400;
  /* opacity: 0; */
}

.deals-item.active .deals-text p {
  opacity: 1;
}

.blog-slider {
  padding: 30px 0;
  position: relative;
}

.blog-slider-left {
  position: relative;
}

.blog-slider-inner {
  padding: 25px;
  position: absolute;
  left: 0;
  right: -100px;
  z-index: 1;
  border: 5px solid #71296f;
}

.slider-text {
  max-width: calc(100% - 100px);
}

.slider-text h3 {
  font-weight: bold;
  font-size: 30px;
  color: #71296f;
  text-transform: uppercase;
}

.slider-text p {
  font-size: 16px;
}

.slider-arrrow {
  cursor: pointer;
}

.slider-arrrow.right {
  text-align: right;
}

.slider-arrrow.left {
  text-align: left;
  padding-left: 100px;
}

.slider-img img {
  width: 100%;
}

.testimonials-main {
  padding: 50px 0;
}

.testimonial-arrow {
  cursor: pointer;
  text-align: center;
}

.testimonial-card {
  color: #ffffff;
  text-align: center;
  position: relative;
  border-radius: 30px;
  padding: 80px;
  background-image: linear-gradient(180deg, #71296f 0%, #391538 100%);
}

.testimonial-card::before {
  content: "";
  position: absolute;
  top: -45px;
  left: 0;
  width: 120px;
  height: 110px;
  background-size: contain;
  background-image: url("./assets/qoutes-left.png");
}

.testimonial-card::after {
  content: "";
  position: absolute;
  bottom: -45px;
  right: 0;
  width: 120px;
  height: 110px;
  background-size: contain;
  background-image: url("./assets/qoutes-right.png");
}

.testimonial-card .card-img {
  position: absolute;
  top: -130px;
  margin: auto;
  left: 0;
  right: 0;
}

.testimonial-card h3 {
  font-size: 28px;
  max-width: 500px;
  margin: auto;
}

.testimonial-card p {
  font-weight: 300;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.payment-item {
  text-align: center;
}

.payment-item img {
  max-width: 100%;
}

.privacy-section {
  margin-bottom: 50px;
}

.privacy-block {
  padding-top: 75px;
}

.privacy-heading {
  font-size: 3.5rem;
  color: #1e2731;
  line-height: 1;
}

.privacy-sub-heading {
  color: #1e2731;
  font-size: 1.25rem;
  margin: 0;
}

.privacy-mini-heading {
  font-size: 1.25rem;
  line-height: 1;
  margin-bottom: 0;
}

.privacy-block p,
.privacy-block li {
  margin-top: 0;
  color: #67758a;
}

.privacy-block li:last-child() {
  margin-bottom: 0;
}

.privacy-block a {
  color: #1e2731;
  word-break: break-all;
  text-decoration: none;
  transition: all 0.3s ease;
}

.privacy-block a:hover {
  color: #f5d312;
}

.privacy-menu {
  padding: 15px;
  top: 10%;
  position: sticky;
  box-shadow: 1px 1px 30px -15px #cdcdcd;
}

.privacy-menu ul {
  padding: 0;
}

.privacy-menu ul li {
  list-style-type: none;
}

.privacy-menu ul li a {
  padding: 15px;
  color: #1e2731;
  font-weight: 500;
  display: block;
  text-decoration: none;
  transition: all 0.3s ease;
}

.privacy-menu ul li a:hover {
  color: #f5d312;
}

@media only screen and (max-width: 900px) {
  .banner-right-heading {
    display: none;
  }

  .callback-button {
    display: none;
  }

  .callback-button-left,
  .callback-button-right {
    display: block !important;
  }

  .request-loader {
    display: none;
  }

  .banner {
    padding-top: 80px;
  }

  .banner-heading {
    font-size: 40px;
    line-height: 45px;
  }

  .banner-bottom {
    padding: 0;
  }

  .blog-slider {
    padding: 0;
  }

  .blog-slider-inner {
    position: relative;
    right: 0;
    left: 0;
    padding: 10px;
  }

  .blog-slider-inner .slider-text {
    max-width: 100%;
  }

  .blog-slider-inner .slider-text h3 {
    margin: 0;
  }

  .slider-arrrow.left {
    padding-left: 0;
  }

  .slider-arrrow.left,
  .slider-arrrow.right {
    text-align: center;
  }

  .home-section {
    padding-top: 0;
  }

  .testimonials-main {
    padding: 0;
  }

  .testimonial-card {
    padding: 80px 0 80px 0;
  }

  .testimonial-card .card-img {
    position: relative;
    top: 0;
  }

  .testimonial-arrow-right {
    text-align: right;
  }

  .testimonial-arrow-left {
    text-align: left;
  }

  .home-section {
    padding-top: 0;
  }

  .search-btn {
    display: none;
    width: 100%;
    border: 0;
    border-radius: 16px;
  }

  .sm-button {
    display: block;
  }

  .cities-gallery {
    padding-bottom: 20;
  }

  .cities-main-heading {
    margin: 20px auto 50px auto;
    font-size: 30px;
    line-height: 40px;
  }

  .cities-main-heading::after,
  .cities-main-heading::before {
    display: none;
  }

  .image-title {
    line-height: 67px;
    letter-spacing: 20px;
    font-size: 40px;
  }

  .image-title.small {
    font-size: 24px;
  }

  .slide-image-to-right {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .banner-heading {
    font-size: 30px !important;
    line-height: 36px !important;
  }

  .banner-form {
    margin: 0;
  }

  .image-title {
    font-size: 26px;
  }

  .home-common-heading {
    font-size: 34px !important;
  }

  .MuiButtonBase-root.MuiTab-root {
    font-size: 0.775rem;
    padding: 12px 10px;
  }

  .MuiFormControl-root.MuiTextField-root {
    margin-top: 0;
  }

  .MuiFormControl-root.MuiTextField-root.MuiInputLabel-root {
    line-height: 1.275em;
    font-size: 0.75rem;
  }
}
/* *UTILS */
.pt-5 {
  padding-top: 1rem;
}
.mt-5 {
  margin-top: 5rem;
}
