.callback-button {
    text-decoration: none;
    cursor: pointer;
    position: fixed;
    z-index: 999999;
    bottom: 40px;
    right: 40px;
}

.callback-action {
    color: #71296f;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    text-align: center;
    font-weight: 600;
    text-decoration: none;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    background: linear-gradient(276deg, #B28D3A 0%, #B79B50 27%, #DEBD5F 68%, #EDD07B 86%, #FFE9A2 100%);
}

.callback-button.callback-button-left, .callback-button.callback-button-right {
    display: none;
    bottom: 8px;
}

.callback-button.callback-button-left {
    left: 10px;
}

.callback-button.callback-button-left {
    right: 10px !important;
}

.callback-button.callback-button-left a, .callback-button.callback-button-right a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 100%;
}


.callback-action::after {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0;
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 100%;
    border: 15px solid #FFE9A2;
    -webkit-animation-name: ripple;
    animation-name: ripple;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
}

.callback-action::before {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0;
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 100%;
    border: 15px solid #FFE9A2;
    -webkit-animation-name: ripple;
    animation-name: ripple;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
}

.callback-button.callback-button-left a::after,
.callback-button.callback-button-left a::before,
.callback-button.callback-button-right a::after,
.callback-button.callback-button-right a::before {
    border-radius: 100%;
}

@-webkit-keyframes ripple {
    from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
    }

    to {
        opacity: 0;
        transform: scale3d(1.5, 1.5, 1);
    }
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
    }

    to {
        opacity: 0;
        transform: scale3d(1.5, 1.5, 1);
    }
}