@keyframes fadeIn {
    from {
        opacity: 0;
    }
}

.full-page-loader {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 999999;
    align-items: center;
    justify-content: center;
    background-color: #71296f;
}

.full-page-loader img {
    text-align: center;
    animation: fadeIn 1s infinite alternate;
}